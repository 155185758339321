<!--
 * @Author: LXG
 * @Date: 2021-04-26
 * @Editors: LXG
 * @LastEditTime: 2021-06-26
 * @Description: 工作台-我的项目 - 政策申报项目弹窗
-->
<template>
  <el-dialog
    class="project-detail-dialog"
    v-setDialogSize="{ visible: visible, height: height }"
    width="1024px"
    :visible.sync="visible"
    :title="title"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="closed"
  >
    <div class="pdd-content-inner" v-loading="loading">
      <div class="content-left">
        <el-tabs class="content-tabs" v-model="tabInfo.active" type="card">
          <el-tab-pane name="base" label="基本信息">
            <Enterprise v-model="enterpriseData" disabled></Enterprise>
            <Agent v-model="agentData" disabled></Agent>
          </el-tab-pane>
          <el-tab-pane name="form" label="项目信息">
            <ParseFormViewDiv
              v-if="formData"
              ref="parseFormViewDiv"
              :formData="formData"
              :readonly="true"
            ></ParseFormViewDiv>
            <!-- 回显新增的两个字段 -->
            <el-form
            :model="newFormCache"
            ref="otherProjectInfo"
            label-position="left"
          >
            <el-form-item
              label-width="220px"
              label="该政策是否有其他项目需要申报"
            >
              <el-radio-group v-model="newFormCache.otherFlag" disabled>
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="本次申报项目名称"
              label-width="170px"
              v-if="newFormCache.otherFlag"
            >
              <el-input
                disabled
                v-model="newFormCache.otherPorject"
                style="border: none"
              ></el-input>
            </el-form-item>
          </el-form>
          </el-tab-pane>
          <el-tab-pane name="material" label="材料信息">
            <el-table :data="materialData" border stripe default-expand-all>
              <el-table-column type="expand">
                <template slot-scope="scope">
                  <FormFileUploader
                    v-if="scope.row.masterinfoList"
                    v-model="scope.row.masterinfoList"
                    disabled
                  ></FormFileUploader>
                </template>
              </el-table-column>
              <el-table-column
                label="序号"
                type="index"
                width="50"
                align="center"
              ></el-table-column>
              <el-table-column label="材料名称">
                <template slot-scope="scope">
                  <span style="font-weight: bold">{{
                    scope.row.clmc || ""
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="是否通过">
                <template slot-scope="scope">
                  <span :style="scope.row.shzt=='1'?'font-weight: bold;color:#13CE66':'font-weight: bold;color:red'">{{
                    handlePass(scope.row.shzt)
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="审核意见">
                <template slot-scope="scope">
                  <span :style="scope.row.shzt=='1'?'font-weight: bold;color:#13CE66':'font-weight: bold;color:red'">{{
                    scope.row.shyj || ""
                  }}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane name="zzje" label="资助金额" v-if="this.status=='1'">
            <el-form :mode="zzjeForm">
              <el-form-item :label="'资助金额'+(index+1)" label-width="100px" v-for="(item,index) in zzjeForm.zzjeData" :key="item.sxid">
                <FormInput v-model="projectData.factnbje" disabled></FormInput>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane
            v-if="evaluationData && !!evaluationData.length"
            name="evaluation"
            label="评价信息"
          >
            <el-timeline>
              <el-timeline-item
                v-for="(item, index) in evaluationData"
                :key="index"
                class="evaluate-item"
                :timestamp="item.pjtime"
                placement="top"
              >
                <el-card>
                  <div class="evaluateItem-score">
                    <div class="evaluateItem-score-item">
                      <label>满意度</label>
                      <el-rate :value="item.myd" disabled></el-rate>
                    </div>
                    <div class="evaluateItem-score-item">
                      <label>处理速度</label>
                      <el-rate :value="item.clsd" disabled></el-rate>
                    </div>
                    <div class="evaluateItem-score-item">
                      <label>服务态度</label>
                      <el-rate :value="item.fwtd" disabled></el-rate>
                    </div>
                  </div>
                  <p v-if="item.msg" class="evaluateItem-msg">{{ item.msg }}</p>
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </el-tab-pane>
        </el-tabs>
        <!-- 服务评价 -->
        <el-form
          v-if="cpu_isBanjie"
          class="form-pj"
          :model="pjData"
          label-width="100px"
        >
          <h3>服务评价</h3>
          <el-row>
            <el-col :span="8">
              <el-form-item label="满意度">
                <el-rate v-model="pjData.myd"></el-rate>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="处理速度">
                <el-rate v-model="pjData.clsd"></el-rate>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="服务态度">
                <el-rate v-model="pjData.fwtd"></el-rate>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="评价意见">
                <FormTextarea v-model="pjData.msg"></FormTextarea>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="content-right">
        <el-tabs class="content-tabs" :value="'log'" type="card">
          <el-tab-pane name="log" label="日志同步">
            <el-timeline>
              <el-timeline-item
                v-for="(item, index) in logData"
                :key="index"
                class="log-item"
                type="success"
                :color="['4','2'].includes(item.operattype)?'red':'success'"
                :timestamp="item.handletime"
                v-show="['1','2','4','5'].includes(item.operattype)"
                placement="top"
              >
                <el-card>
                  <div class="logItem-node" :style="['4','2'].includes(item.operattype)?'color:red':''">
                    {{ handleNodename(item.nodename) }}<img v-show="['完善信息','拨付资金'].includes(item.nodename)" src="@/assets/images/finished.png" alt="" style="width: 22px;height: 22px;vertical-align: middle;margin-left: 5px;">
                    </div>
                  <div v-if="item.handlername" style="margin-top: 10px">
                    <p class="logItem-handler" style="margin-bottom:5px">{{ item.deptname }}：{{ item.handlemsg }}</p>
                  </div>
                </el-card>
              </el-timeline-item>
               <el-timeline-item
                v-if="logShow&&status!=='3'&&status!=='2'"
                :key="index"
                class="log-item"
                type="success"
                color="#FFCB19"
                timestamp=""
                placement="top"
              >
                <el-card >
                  <div class="logItem-node" style="color:#FFCB19">办理中...</div>
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <template slot="footer">
      <el-button
        v-for="(btn, index) in cpu_footBtns"
        :key="index"
        :type="btn.type"
        @click="clickBtn(btn)"
      >
        {{ btn.label }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import Enterprise from "@/views/policyDeclaration/components/enterprise/Enterprise";
import Agent from "@/views/policyDeclaration/components/agent/Agent";
import ParseFormViewDiv from "@/components/declareComponents/formMaking/ParseFormViewDiv";
import { FormTextarea, FormFileUploader } from "@/components/form/index.js";
import $projectDetailDialog from "./index.js";
import { FormInput } from "@/components/form/index.js";
export default {
  name: "ProjectDetailDialog",
  components: {
    Enterprise,
    Agent,
    ParseFormViewDiv,
    FormTextarea,
    FormFileUploader,
    FormInput,
  },
  directives: {
    setDialogSize: function (el, binding, vm) {
      // console.log('setDialogSize:', el, binding, vm)
      const cfg = binding.value;
      if (!cfg.visible) return;

      const dialog = el.childNodes[0];
      dialog.style.height = cfg.height;
    },
  },
  props: {
    // dialog高度
    height: {
      type: String,
      default: "auto",
    },
    // dialog标题
    title: {
      type: String,
      default: "政策项目详情",
    },
    // 政策项目ID
    projectId: {
      type: String,
    },
    // 用户信息
    //     万万没想到啊，js调用，拿不到store?
    userInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 事项id
    sxid:{
      type: String,
    },
    // 办结状态，1为已办结
    status:{
      type:String
    }
  },
  data() {
    return {
      newFormCache: {
        otherFlag: "",
        otherPorject: "",
      },
      zzjeForm:{
        zzjeData:[],
      },//资助金额
      visible: false, // dialog显示
      tabInfo: {
        active: "base",
      },
      loading: true,
      projectData: {}, // 政策申报项目基本信息
      enterpriseData: {}, // 企业信息
      agentData: {}, // 经办人信息
      formData: null, // 项目信息(表单信息)
      materialData: [], // 材料信息
      evaluationData: [], // 评价信息
      logData: [], // 日志信息
      logShow: true, //办理中是否显示
      // 评价表单
      pjData: {
        myd: 5, // 满意度
        clsd: 5, // 处理速度
        fwtd: 5, // 服务态度
        msg: "", // 评价意见
      },
    };
  },
  computed: {
    /**
     * @description 是否为已办结(已拨付)
     */
    cpu_isBanjie() {
      return this.projectData.status == "1";
    },
    cpu_footBtns() {
      let list = [{ label: "关闭", handleName: "close" }];
      if (this.cpu_isBanjie) {
        list.push({ label: "评价", type: "primary", handleName: "evaluate" });
      }

      return list;
    },
  },
  methods: {
    // 处理通过不通过
    handlePass(val){
      let label = "";
      switch (val) {
        case "1":
          label = "通过"
          break;
        case "0":
          label = "不通过";
          break;
        case "2":
          label = "不通过"
          break;
        default:
          label = ""
          break;
      }
      return label
    },
    // 异常办结改为不通过
    handleNodename(nodename){
      let name = nodename
      if(nodename.indexOf('异常办结')!=-1){
        name = name.replace(/异常办结/,'不通过')
      }
      return name;
    },
    // 获取资助金额
    getZzje(){
      let url = "/dev-api/business/sq-apply/selectZzqxList";
      let param = {
        sxid:this.sxid
      }
      let that = this;
      that.$httpApi.post(url,param).then(res=>{
        that.zzjeForm.zzjeData = res.data;
      }).catch(e=>{
        console.log(e);
      })

    },
    /**
     * @description: 点击按钮
     * @param {Object} btn 按钮
     */
    clickBtn(btn) {
      // btn.click.call(this, btn)
      this[btn.handleName]();
    },
    /**
     * @description 评价
     */
    evaluate() {
      console.log(this.userInfo);
      let url = "/dev-api/sqAdvice/add";
      let params = {
        userid: this.userInfo.userId,
        bsnum: this.projectData.id,
        itemtype: "1",
        ...this.pjData,
      };
      this.$httpApi
        .post(url, params)
        .then((res) => {
          // console.log('evaluate:', res)
          this.$message.success("操作成功");
          this.close();
        })
        .catch((err) => {
          console.log("evaluate error:", err);
        });
    },
    /**
     * @description: 关闭dialog
     */
    close() {
      this.visible = false;
    },
    /**
     * @description: 关闭dialog结束时回调
     */
    closed() {
      $projectDetailDialog.destroy();
    },
    /**
     * @description 查询项目信息
     * @param {String} projectId 项目ID
     */
    getProjectData(projectId) {
      let url = `/dev-api/userBusiness/getBusinessInfo?id=${projectId}`;
      let params = {
        // id: projectId,
      };
      this.$httpApi
        .post(url, params)
        .then((res) => {
          console.log("getProjectData:", res);
          this.loading = false;
          if (res.code == 200) {
            this.projectData = res.data.masterinfo;
            this.newFormCache.otherFlag = Number(res.data.masterinfo.otherFlag);
            this.newFormCache.otherPorject = res.data.masterinfo.otherPorject
            let baseData = JSON.parse(res.data.basicInfo.subdata);
            this.enterpriseData = baseData.enterprise || {};
            this.agentData = baseData.agent || {};

            this.formData = JSON.parse(res.data.formData.formdata);
            this.$nextTick(() => {
              if (this.formData) {
                this.$refs["parseFormViewDiv"].fillFormData(
                  this.formData,
                  JSON.parse(res.data.formData.subdata)
                );
              }
            });
            this.materialData = res.data.material || [];
            for (let i = 0; i < this.materialData.length; i++) {
              this.materialData[i].masterinfoList = this.materialData[
                i
              ].masterinfoList.map((cv) => {
                return {
                  ...cv,
                  name: cv.filename,
                  url: `${this.$httpApi.baseUrl}/dev-api/fileManageApi/common/fileView/${cv.fileid}`,
                };
              });
            }
            this.logData = res.data.log || [];
            if(this.logData[this.logData.length-1].operattype=='5'){
              this.logShow = false
            }
          }
        })
        .catch((err) => {
          console.log("getProjectData error:", err);
          this.loading = false;
        });
    },
    /**
     * @description 查询评价信息
     * @param {String} id 政策项目ID
     */
    getEvaluationData(id) {
      let url = "/dev-api/sqAdvice/list";
      let params = {
        bsnum: id,
        itemtype: "1",
        pjstatus: "1",
      };
      this.$httpApi
        .post(url, params)
        .then((res) => {
          console.log("getEvaluationData:", res);
          if (res.code == 200) {
            this.evaluationData = res.data || [];
          }
        })
        .catch((err) => {
          console.log("getEvaluationData error:", err);
        });
    },
  },
  created() {
    this.getProjectData(this.projectId);
    this.getEvaluationData(this.projectId);
    this.getZzje();

  },
};
</script>

<style lang="scss" scoped>
.project-detail-dialog {
  ::v-deep .el-dialog__body {
    height: 55vh;
    padding: 0 20px;
    margin-top: 20px;
    overflow-y: auto;
  }
  .pdd-content-inner {
    display: -webkit-flex;
    display: flex;

    .content-left {
      flex: 1;
      min-width: 0;
    }
    .content-right {
      width: 300px;
    }
  }
}
::v-deep .content-tabs {
  .el-tabs__header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 9;
  }
  .el-tabs__item {
    font-size: 16px;

    &:hover,
    &.is-active {
      color: $col-theme;
    }
    &.is-active {
      font-weight: bold;
    }
  }
  .el-tabs__content {
    padding: 0 10px 10px 10px;
  }
}
::v-deep .enterprise {
  .wrap-title {
    font-size: 16px;
    border: none;
    color: #333333;
  }
}
::v-deep .personal {
  .p-title {
    font-size: 16px;
    border: none;
    color: #333333;
  }
}
::v-deep .evaluate-item {
  .el-card__body {
    padding: 10px;
  }
  .evaluateItem-score {
    display: -moz-grid;
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .evaluateItem-score-item {
      display: -webkit-flex;
      display: flex;
      align-items: center;

      label {
        margin-right: 10px;
        color: #999999;
      }
    }
  }
  .evaluateItem-msg {
    margin-top: 10px;
    color: #999999;
  }
}
::v-deep .log-item {
  .el-timeline-item__node {
    background-color: $col-green;
  }
  .el-card__body {
    padding: 10px;

    .logItem-node {
      font-size: 16px;
      color: $col-green;
    }
    .logItem-handler,
    .logItem-handleMsg {
      color: #999999;
    }
    .logItem-handler {
      float: left;
      margin-right: 10px;
    }
    .logItem-handleMsg {
      overflow: hidden;
    }
  }
}
::v-deep .form-pj {
  padding-top: 10px;
  border-top: 2px solid #333333;
  margin-top: 20px;

  h3 {
    position: relative;
    padding-left: 16px;
    margin-bottom: 10px;
    color: #333333;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 5px;
      height: 100%;
      background-color: $col-theme;
      border-radius: 5px;
    }
  }
  .el-rate {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
</style>
