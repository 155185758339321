<template>
  <div class="work-content-left" v-loading="loading">
    <div class="wrap-header">
      <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane
          v-for="(item, index) in cpu_tabList"
          :key="index"
          :name="item.status"
        >
          <template v-slot:label>
            <p class="tabItem-label" :class="'tabItem-label-' + index">
              <span>{{ item.name }}</span>
              <span v-if="item.count" class="tabItem-count">{{
                item.count
              }}</span>
            </p>
          </template>
        </el-tab-pane>
      </el-tabs>
      <span class="header-count"
        >共 {{ projectTotal.total }} 个政策申报项目</span
      >
    </div>
    <div class="wrap-content">
      <div
        v-for="(item, index) in showList"
        :key="index"
        class="project-show-content flex-row-between"
        :class="{ 'item--pointer': cpu_isBofu(item) }"
        @click="clickItem(item)"
      >
        <!-- 左上角标签 -->
        <div
          class="projectItem-status"
          :style="{
            'background-color': cpu_getStatusColor(item),
            'border-color': cpu_getStatusColor(item),
          }"
        >
          {{ cpu_statusLabel(item) }}
        </div>
        <div class="flex-row proshow-left">
          <div class="flex-column proshow-text">
            <div class="proshow-title">
              <span>{{ item.sxmc || "" }}</span>
              <span v-show="item.otherPorject" style="font-size:14px;font-weight:400;margin-left:10px;color:#ff6a00">(项目信息：{{item.otherPorject}})</span>

            </div>
            <div class="projectItem-info">
              <div
                v-for="(infoItem) in projectInfo2.infoList"
                :key="infoItem.valueKey"
                v-show="
                  infoItem.formatter
                    ? infoItem.formatter(item)
                    : item[infoItem.valueKey] && item.status == '10'
                "
                class="projectItem-info-item"
              >
                <!-- <label class="infoItem-label" v-if="item.status=='10'">暂存编号:</label>
                                <label class="infoItem-label" v-else>{{infoItem.label}}：</label> -->
                <label class="infoItem-label">{{ infoItem.label }}:</label>
                <div class="infoItem-value">
                  {{
                    infoItem.formatter
                      ? infoItem.formatter(item)
                      : item[infoItem.valueKey]
                  }}
                </div>
              </div>
              <div
                v-for="(infoItem, jIndex) in projectInfo.infoList"
                :key="jIndex"
                v-show="
                  infoItem.formatter
                    ? infoItem.formatter(item)
                    : item[infoItem.valueKey] && item.status != '10'
                "
                class="projectItem-info-item"
              >
                <label class="infoItem-label">{{ infoItem.label }}:</label>
                <div class="infoItem-value">
                  {{
                    infoItem.formatter
                      ? infoItem.formatter(item)
                      : item[infoItem.valueKey]
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="proshow-btn">
          <!-- 只有这几个状态才可以修改: 6暂存件(暂存)status字段不存在则是暂存件 2异常办结、3补交 -->
          <div>
            <el-button
              v-if="
                !item.status ||
                item.status == 3 ||
                item.status == 10
              "
              style="margin-bottom: 10px"
              type="primary"
              size="medium"
              icon="el-icon-edit"
              @click="gotoDeclare(item)"
              >修改项目
            </el-button>
          </div>
          <div>
            <el-button
              v-show="item.status != 10"
              class="projectItem-btn btn-more"
              size="medium"
              @click="viewDetail(item)"
              >查看详情</el-button
            >
          </div>
          <!-- <el-button
                                v-if="item.status == 5"
                                type="primary"
                                size="small"
                                icon="el-icon-edit"
                                @click="exportPDF(item)"
                            >导出PDF文档
                            </el-button> -->
          <!--status不为空,则表示不是待提交(暂存件),显示进度-->
          <!-- <div
                        class="other-btns"
                        v-show="item.status"
                    >
                        <el-popover
                            @show="showProgress(item)"
                            placement="left"
                            title="申报进度"
                            width="300"
                            trigger="hover"
                        >
                            <el-timeline>
                                <el-timeline-item
                                    v-for="(activity, index) in showActivity"
                                    :key="index"
                                    :timestamp="activity.timestamp"
                                >{{ activity.content }}
                                </el-timeline-item>
                            </el-timeline>
                            <div slot="reference">
                                进度查看
                                <div class="arrow-bm"></div>
                            </div>
                        </el-popover>
                    </div> -->
        </div>
      </div>
      <NoDataView
        :dataState="
          !loading && (showList == null || showList.length == 0)
            ? 'noData'
            : 'init'
        "
      />
    </div>
    <div class="recent-projects margin-bottom flex-column">
      <div class="pro-content flex-column"></div>
    </div>
    <!-- 页尾 -->
    <div class="pro-pagination">
      <el-pagination
        hide-on-single-page
        style="margin-top: -38px"
        layout="total,prev,pager,next,jumper"
        background
        :total="totalSizes"
        :page-size="pagerSize"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import NoDataView from "@/components/nodata-view";
import $projectDetailDialog from "./projectDetailDialog/index.js";
import variableStyle from "@/styles/variable.scss";
export default {
  components: {
    NoDataView,
  },
  inject: {
    updateProjectTotal: {
      from: "updateProjectTotal",
    },
  },
  data() {
    return {
      // PDF的路径
      PDFherf: "",
      loading: true,
      activeName: "qbxm", // 类型切换
      pagerSize: 4, // 每页显示多少条数据
      totalSizes: 0, // 总条数
      currentPage: 1,
      showActivity: [], //进度列表
      showList: [],
      /* 全部项目 */
      allProList: [],
      // 统计
      projectTotal: {
        total: 0,
        dtjnum: 0, // 暂存件
        shnum: 0, // 审核中
        thnum: 0, // 已退回
        bjnum: 0, // 已办结
        byslnum: 0, //不予受理
        factnbje: 0, // 已获扶持总资金
      },
      projectInfo: {
        infoList: [
          { label: "项目业务号", valueKey: "id" },
          { label: "申报时间", valueKey: "createtime" },
          // {
          //     label: '申报金额', formatter: (obj) => {
          //         if (!Number(obj.factnbje)) return ''
          //         return `${obj.factnbje} 万元`
          //     }
          // },
        ],
      },
      projectInfo2: {
        infoList: [
          { label: "暂存编号", valueKey: "id" },
          { label: "暂存时间", valueKey: "createtime" },
          // {
          //     label: '申报金额', formatter: (obj) => {
          //         if (!Number(obj.factnbje)) return ''
          //         return `${obj.factnbje} 万元`
          //     }
          // },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
    }),
    cpu_tabList() {
      /**
       * 20210524
       * 这里的status，跟实际的项目status可能不是对应的
       * 这里的stauts要保证唯一，用于发出请求前做判断，传不同的参数
       */
      return [
        { name: `全部项目`, count: this.projectTotal.total, status: "qbxm" },
        { name: `暂存件`, count: this.projectTotal.dtjnum, status: "6" },
        { name: `审核中`, count: this.projectTotal.shnum, status: "0,4,5,6,7" },
        { name: `已退回`, count: this.projectTotal.thnum, status: "3" },
        { name: `已办结`, count: this.projectTotal.bjnum, status: "1" },
        { name: `不通过`, count: this.projectTotal.btgnum, status: "2" }, //（原来的异常件）
      ];
    },
    /**
     * @description: 审核中且处于资金拨付阶段的状态
     * @param {Object} item 政策项目
     */
    cpu_isBofu() {
      return function (item) {
        // return this.activeName == '0' && true
        return false;
      };
    },
    /**
     * @description: 获取tab颜色
     * @param {Object} project 项目信息
     * @return {String}
     */
    cpu_getStatusColor() {
      return function (project) {
        switch (project.status) {
          case "0":
          case "5":
            // 审核中
            return variableStyle["color-orange"];
          // 已办结
          case "1":
            return variableStyle["color-green"];
          // 异常件/补交件
          case "2":
          case "3":
            return variableStyle["color-red"];
          // 暂存件
          default:
            return "#7e97ac";
        }
      };
    },
    /**
     * @description 项目状态显示文本
     * @param {Object} project 项目
     * @return {String}
     */
    cpu_statusLabel() {
      return function (project) {
        /**
         * 20210524
         * 状态的判断逻辑 得重新梳理清楚了
         *     审核中: status:'0' || status:'4' || status:'5' || status:'6' || status:'7'
         *     已办结: status:'1'
         *     已退回: status:'2'(异常件) || status:'3'(补交件)
         *     暂存件: 其他
         */
        switch (project.status) {
          case "0":
          case "4":
          case "5":
          case "6":
          case "7":
            return "审核中";
          case "1":
            return "已办结";
          case "2":
            // return "异常件";
            return "不通过";
          case "3":
            return "补交件";
          default:
            return "暂存件";
        }
      };
    },
  },
  created() {
    this.getProjectTotal();
    this.loadTempSaveData();
  },
  mounted() {
    this.loadData();
    // this.loadTempSaveData()
  },
  methods: {
    /**
     * @description 查看详情
     * @param {Object} project 政策项目信息
     */
    viewDetail(project) {
      $projectDetailDialog.init({
        projectId: project.id,
        userInfo: this.userInfo,
        title: project.sxmc,
        sxid: project.sxid,
        status: project.status,
      });
    },
    /**
     * @description: 点击政策项目
     * @param {*} item 政策项目信息
     */
    clickItem(item) {
      if (!this.cpu_isBofu(item)) return;
      $policyDeclarationDialog.init({});
    },
    showProgress(item) {
      var activity = [];
      this.showActivity = activity;
      this.$httpApi
        .post(`/dev-api/userBusiness/getBusinessInfo?id=${item.id}`, {})
        .then((res) => {
          var logs = res.data.log;
          if (res.code == 200 && logs) {
            logs.forEach((element) => {
              activity.push({
                content: element.nodename,
                timestamp: element.handletime,
              });
            });
          } else {
            activity.push({
              content: "暂无信息",
            });
          }
          this.showActivity = activity;
          console.log(this.showActivity);
        })
        .catch((e) => {
          activity.push({
            content: "暂无信息",
          });
          this.showActivity = activity;
        });
    },
    /* 暂存 */
    loadTempSaveData() {
      this.loading = true;
      let identify = this.userInfo.tyshxydm;
      let param = {
        pageNum: this.currentPage,
        pageSize: this.pagerSize,
        identify: identify,
      };
      this.$httpApi
        .postByForm("/dev-api/userBusiness/zclist", param)
        .then((res) => {
          console.log("暂存件列表:", res);
          if (res.code == 200 && res.rows.length > 0) {
            // let resList = res.rows;
            /* 序列化 */
            // for (const item of resList) {
            //     item.bsdata = JSON.parse(item.bsdata);
            //     item.sxmc = item.bsdata.baseinfo.sxmc;
            //     item.createtime = item.createtime.split("T")[0];
            //     this.showList.push(item);
            // }
            this.showList = res.rows;
            this.totalSizes = res.total;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    /**
     * 查询业务信息列表
     */
    loadData() {
      this.loading = true;
      // 以服务的方式调用的 Loading 需要异步关闭
      let params = {
        pageNum: this.currentPage,
        pageSize: this.pagerSize,
        itemtype: 1,
      };
      switch (this.activeName) {
        case "qbxm":
          params.status = "";
          break;
        default:
          params.status = this.activeName;
          break;
      }
      if (this.userInfo.tyshxydm) {
        params["tyxydm"] = this.userInfo.tyshxydm;
      } else {
        params["applicantid"] = this.userInfo.id;
      }
      // console.log("提交参数>>>", params);
      this.$httpApi
        .get("/dev-api/userBusiness/list", params)
        .then((res) => {
          console.log("查询业务信息列表>>>", res);
          this.loading = false;
          if (res.code == 200 && res.rows.length > 0) {
            this.showList = res.rows;
            this.totalSizes = res.total;
            // 业务状态 0在办 1正常办结 2异常办结 3补交 4特别程序 5预受理
            // this.getAllPro();
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    gotoDeclare(item) {
      if(item.sxid=='e3d1fa8f6e5c4620ab28ba63d1b272fb' || item.sxid=='7d7cd03229954c269756ef4e4df06a5c'){
      // 测试用的sxid
      // if(item.sxid=='31a2d9fe9d674573905821948c55dfc0'){
        // 只有事项id为这两个的时候，页面显示的字段跟其他不一样
        sessionStorage.setItem("isSpecialProject",'true')
      }else {
        sessionStorage.setItem("isSpecialProject",'false')
      }
      console.log("gotoDeclare:", item);
      /**
       * 20210615
       * 前往新的政策项目申报页面
       */
      let templateInfo = {
        templateFlag: 1,
        templateFileId: item.templateFileId,
        templateFileName: item.templateFileName,
      };
      templateInfo = JSON.stringify(templateInfo)
      let params = {
        pId: item.id,
        policyId: item.sxid,
        templateInfo:templateInfo
      };
      if (item.status == "10") {
        params["status"] = item.status;
        params["step"] = item.step;
        params['zcid'] = item.id;//暂存id
      }
      this.$router.push({
        name: "policyDeclaration",
        query: params,
      });
      // this.$store.commit("updateCurrentDeclareItem", null);
      // this.$store.commit("updateCurrentProjectItem", item);
      // // 根据itemType跳转不同的申报页面
      // if (item.itemtype == 3) {
      //     //诉求
      //     this.$router.push({
      //         name: "declare",
      //     });
      // } else {
      //     //审批
      //     this.$router.push({
      //         name: "declareShenpi",
      //         query: {
      //             isBack: "0"
      //         }
      //     });
      // }
    },
    /* 分页切换时，更换显示的数组 */
    handleCurrentChange(index) {
      let type = this.activeName;
      this.currentPage = index;
      //清空显示的数据
      this.showList = [];
      if (type == "6") {
        this.loadTempSaveData();
      } else {
        this.loadData();
      }
    },
    /**
     * 切换
     */
    handleTabClick() {
      this.currentPage = 1;
      //清空显示的数据
      this.showList = [];
      this.totalSizes = 0;
      let type = this.activeName;
      if (type == "6") {
        this.loadTempSaveData();
      } else {
        this.loadData();
      }
      // 点击tab刷新列表数据时，同步刷新统计数目
      this.getProjectTotal();
    },
    // 导出PDF
    // exportPDF(item) {
    //     let params = {
    //         delete: true,
    //     }
    //     params.fileName = item.appcompany + item.sxmc;
    //     params.ftlName = "ba_industrial_development.ftl"
    //     let year = this.getYear(item.createtime)
    //     let month = this.getMonth(item.createtime)
    //     let day = this.getDay(item.createtime)
    //     let data = {
    //         "wzyj": "",
    //         "year": year,
    //         "remark": " ",
    //         "sxmc": item.sxmc,
    //         "ywri": "    年    月    日",
    //         "wzri": "    年    月    日",
    //         "nbfje": "",
    //         "number": "",
    //         "appcompany": item.appcompany,
    //         "appname": " ",
    //         "month": month,
    //         "ywyj": "",
    //         "id": "2103111127160004",
    //         "tyxydm": item.id,
    //         "wzshr": "",
    //         "gsyy": "",
    //         "day": day,
    //         "applyphone": " ",
    //         "status": item.status,
    //         "ywshr": "",
    //     }
    //     console.log(data)
    //     params.data = encodeURIComponent(JSON.stringify(data))
    //     console.log("导出PDF传参", params)
    //     // this.$httpApi
    //     // .getByBody("/common/download_web",params)
    //     // .then((res) => {
    //     // 	console.log("导出PDF的返回",res)
    //     // })
    //     // .catch((err) => {
    //     // });
    //     let fullUrl = this.$httpApi.baseUrl + "/common/download_web";
    //     this.PDFherf = this.getPDFHerf(fullUrl, params)
    //     window.location.href = this.PDFherf
    // },
    getPDFHerf(fullUrl, params) {
      if (params) {
        fullUrl = fullUrl + "?";
        for (const key in params) {
          fullUrl += key + "=" + params[key] + "&";
        }
        if (fullUrl.lastIndexOf("&")) {
          fullUrl = fullUrl.substring(0, fullUrl.length - 1);
        }
        console.log("PDFherf:", fullUrl);
        return fullUrl;
      }
    },
    getYear(time) {
      let myYear = time.split(" ");
      let returnList = myYear[0].split("-");
      return returnList[0];
    },
    getMonth(time) {
      let myYear = time.split(" ");
      let returnList = myYear[0].split("-");
      return returnList[1];
    },
    getDay(time) {
      let myYear = time.split(" ");
      let returnList = myYear[0].split("-");
      return returnList[2];
    },
    /**
     * @description: 查询政策申报项目统计数目
     */
    getProjectTotal() {
      // let url = '/dev-api/busitjfx/data/find/busi_qy_tjfx'
      let url = "/dev-api/times/countMyWorkBenchNum";
      let params = {
        tyxydm: this.userInfo.tyshxydm,
        itemtype: 1,
      };
      this.$httpApi
        .post(url, params)
        .then((res) => {
          if (res.data) {
            // for (const [k, v] of Object.entries(res.data[0])) {
            //     this.projectTotal[k] = v || 0
            // }
            for (let key in res.data) {
              this.projectTotal[key] = res.data[key] || 0;
            }
            // 同步更新父组件 banner的统计数目
            this.updateProjectTotal(res.data);
          }
        })
        .catch((err) => {
          console.log("getProjectTotal error:", err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/com/theme.scss";
.work-content-left {
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  .recent-projects {
    width: 100%;
    margin-bottom: 60px !important;
    .pro-content {
      width: 100%;
    }
    /* 搜索栏目 */
    .pro-search {
      padding: 0 50px;
    }
  }
}
.project-show-content {
  .proshow-left {
    height: 100%;
    .proshow-text {
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: flex-start;
      .proshow-title {
        color: $color-title;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
  }
  .proshow-btn {
    width: 120px;
    text-align: center;
    // background: chocolate;
    .other-btns {
      margin: 10px 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 11px;
      // font-weight: 300px;
      color: #333;
      .copy-project {
        margin-left: 8px;
      }
      ::v-deep .el-popover__reference {
        padding: 5px 10px;
        border: 1px solid #999999;
        color: #999999;
      }
      & div:hover {
        border-color: $col-theme;
        color: #ffffff;
        background-color: $col-theme;
        cursor: default;
      }
    }
  }
}
.project-show-content {
  position: relative;
  padding: 20px 20px 20px 90px;
  background-color: #ffffff;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  .projectItem-status {
    position: absolute;
    top: 50%;
    left: 20px;
    width: 50px;
    height: 32px;
    border-radius: 4px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      border-top: 8px solid;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-top-color: inherit;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
  &:hover {
    box-shadow: 0 2px 4px 1px rgba($col-theme, 0.2);
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -o-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    transform: translateY(-2px);
    .proshow-title {
      color: $col-theme !important;
    }
  }
}
.project-show-content + .project-show-content {
  margin-top: 5px;
}
.projectItem-info {
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: -10px;
  margin-left: -20px;
  .projectItem-info-item {
    display: -webkit-flex;
    display: flex;
    margin-top: 10px;
    margin-left: 20px;
    color: #999999;
    .infoItem-label {
      margin-right: 5px;
    }
    .infoItem-value {
      flex: 1;
    }
  }
}
::v-deep .nodata-containenr {
  position: relative;
}
.pro-pagination {
  text-align: center;
}
/* 当页公共 */
.card-title {
  font-size: 20px;
  padding: 15px 20px;
  font-weight: 600;
  color: $color-title;
}
</style>
<style lang="scss" scoped>
$tabColors: (
  "0": $col-blue,
  "1": #7e97ac,
  "2": $col-orange,
  "3": $col-red,
  "4": $col-green,
);
.wrap-header {
  position: relative;
  margin-bottom: 10px;
  background-color: #ffffff;
}
::v-deep .el-tabs__header {
  padding-top: 5px;
  .el-tabs__nav-wrap {
    .el-tabs__item {
      padding-right: 20px;
      padding-left: 20px;
      font-size: 16px;
      font-weight: bold;
      .tabItem-label {
        position: relative;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        .tabItem-count {
          position: absolute;
          top: 50%;
          right: 0;
          display: inline-block;
          min-width: 16px;
          height: 16px;
          padding-right: 2px;
          padding-left: 2px;
          border-radius: 16px;
          margin-left: 5px;
          line-height: 16px;
          text-align: center;
          font-size: 12px;
          color: #ffffff;
          background-color: $col-red;
          -webkit-transform: translate(calc(100% + 2px), -50%);
          -moz-transform: translate(calc(100% + 2px), -50%);
          -o-transform: translate(calc(100% + 2px), -50%);
          -ms-transform: translate(calc(100% + 2px), -50%);
          transform: translate(calc(100% + 2px), -50%);
        }
        @each $k, $v in $tabColors {
          &.tabItem-label-#{$k} .tabItem-count {
            background-color: $v;
          }
        }
      }
      &:hover,
      &.is-active {
        color: $col-theme;
        @each $k, $v in $tabColors {
          .tabItem-label-#{$k} {
            color: $v;
          }
        }
      }
    }
    .el-tabs__active-bar {
      background-color: $col-theme;
    }
    &::after {
      display: none;
    }
  }
}
.header-count {
  position: absolute;
  top: 50%;
  right: 20px;
  font-family: Microsoft YaHei;
  color: #828282;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.item--pointer {
  cursor: pointer;
  &:hover {
    background-color: rgba(#cccccc, 0.2);
  }
}
.btn-more {
  &:hover {
    border-color: $col-theme;
    color: #ffffff;
    background-color: $col-theme;
  }
}
</style>
