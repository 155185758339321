var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"work-content-left"},[_c('div',{staticClass:"wrap-header"},[_c('el-tabs',{on:{"tab-click":_vm.handleTabClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.cpu_tabList),function(item,index){return _c('el-tab-pane',{key:index,attrs:{"name":item.status},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"tabItem-label",class:'tabItem-label-' + index},[_c('span',[_vm._v(_vm._s(item.name))]),(item.count)?_c('span',{staticClass:"tabItem-count"},[_vm._v(_vm._s(item.count))]):_vm._e()])]},proxy:true}],null,true)})}),1),_c('span',{staticClass:"header-count"},[_vm._v("共 "+_vm._s(_vm.projectTotal.total)+" 个政策申报项目")])],1),_c('div',{staticClass:"wrap-content"},[_vm._l((_vm.showList),function(item,index){return _c('div',{key:index,staticClass:"project-show-content flex-row-between",class:{ 'item--pointer': _vm.cpu_isBofu(item) },on:{"click":function($event){return _vm.clickItem(item)}}},[_c('div',{staticClass:"projectItem-status",style:({
          'background-color': _vm.cpu_getStatusColor(item),
          'border-color': _vm.cpu_getStatusColor(item),
        })},[_vm._v(" "+_vm._s(_vm.cpu_statusLabel(item))+" ")]),_c('div',{staticClass:"flex-row proshow-left"},[_c('div',{staticClass:"flex-column proshow-text"},[_c('div',{staticClass:"proshow-title"},[_c('span',[_vm._v(_vm._s(item.sxmc || ""))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.otherPorject),expression:"item.otherPorject"}],staticStyle:{"font-size":"14px","font-weight":"400","margin-left":"10px","color":"#ff6a00"}},[_vm._v("(项目信息："+_vm._s(item.otherPorject)+")")])]),_c('div',{staticClass:"projectItem-info"},[_vm._l((_vm.projectInfo2.infoList),function(infoItem){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
                infoItem.formatter
                  ? infoItem.formatter(item)
                  : item[infoItem.valueKey] && item.status == '10'
              ),expression:"\n                infoItem.formatter\n                  ? infoItem.formatter(item)\n                  : item[infoItem.valueKey] && item.status == '10'\n              "}],key:infoItem.valueKey,staticClass:"projectItem-info-item"},[_c('label',{staticClass:"infoItem-label"},[_vm._v(_vm._s(infoItem.label)+":")]),_c('div',{staticClass:"infoItem-value"},[_vm._v(" "+_vm._s(infoItem.formatter ? infoItem.formatter(item) : item[infoItem.valueKey])+" ")])])}),_vm._l((_vm.projectInfo.infoList),function(infoItem,jIndex){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
                infoItem.formatter
                  ? infoItem.formatter(item)
                  : item[infoItem.valueKey] && item.status != '10'
              ),expression:"\n                infoItem.formatter\n                  ? infoItem.formatter(item)\n                  : item[infoItem.valueKey] && item.status != '10'\n              "}],key:jIndex,staticClass:"projectItem-info-item"},[_c('label',{staticClass:"infoItem-label"},[_vm._v(_vm._s(infoItem.label)+":")]),_c('div',{staticClass:"infoItem-value"},[_vm._v(" "+_vm._s(infoItem.formatter ? infoItem.formatter(item) : item[infoItem.valueKey])+" ")])])})],2)])]),_c('div',{staticClass:"proshow-btn"},[_c('div',[(
              !item.status ||
              item.status == 3 ||
              item.status == 10
            )?_c('el-button',{staticStyle:{"margin-bottom":"10px"},attrs:{"type":"primary","size":"medium","icon":"el-icon-edit"},on:{"click":function($event){return _vm.gotoDeclare(item)}}},[_vm._v("修改项目 ")]):_vm._e()],1),_c('div',[_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(item.status != 10),expression:"item.status != 10"}],staticClass:"projectItem-btn btn-more",attrs:{"size":"medium"},on:{"click":function($event){return _vm.viewDetail(item)}}},[_vm._v("查看详情")])],1)])])}),_c('NoDataView',{attrs:{"dataState":!_vm.loading && (_vm.showList == null || _vm.showList.length == 0)
          ? 'noData'
          : 'init'}})],2),_vm._m(0),_c('div',{staticClass:"pro-pagination"},[_c('el-pagination',{staticStyle:{"margin-top":"-38px"},attrs:{"hide-on-single-page":"","layout":"total,prev,pager,next,jumper","background":"","total":_vm.totalSizes,"page-size":_vm.pagerSize},on:{"current-change":_vm.handleCurrentChange}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"recent-projects margin-bottom flex-column"},[_c('div',{staticClass:"pro-content flex-column"})])
}]

export { render, staticRenderFns }