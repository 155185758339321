import Vue from 'vue'
import ProjectDetailDialog from './ProjectDetailDialog'

const cpn = Vue.extend(ProjectDetailDialog)
let inst

const $projectDetailDialog = {
    init(options = {}) {
        inst = new cpn({ propsData: options }).$mount()
        document.querySelector('#app').appendChild(inst.$el)
        // console.log('inst:', inst)

        Vue.nextTick(() => {
            inst.visible = true
        })
    },
    close() {
        if (inst) {
            inst.visible = false
        }
    },
    destroy() {
        document.querySelector('#app').removeChild(inst.$el)
        inst.$destroy()
        inst = null
    },
}

export default $projectDetailDialog