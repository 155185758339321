<!--
 * @Author: LXG
 * @Date: 2021-04-27
 * @Editors: LXG
 * @LastEditTime: 2021-06-15
 * @Description: 政策项目申报 - 经办人信息
-->
<template>
  <div class="personal">
    <h2 class="p-title">联系人基本信息</h2>
    <div>
      <el-form
        ref="form"
        :model="cpu_value"
        size="medium"
        label-width="100px"
        :rules="formRules"
      >
        <el-form-item prop="agentname" label="联系人姓名">
          <FormInput
            v-model="cpu_value.agentname"
            :disabled="disabled"
          ></FormInput>
        </el-form-item>
        <el-form-item prop="agentcertificatenumber" label="证件号码">
          <FormInput
            v-model="cpu_value.agentcertificatenumber"
            placeholder=" "
            :disabled="disabled"
          >
            <template v-slot:prepend>
              <span>身份证</span>
            </template>
          </FormInput>
        </el-form-item>
        <el-form-item prop="agentmobilephone" label="联系电话">
          <FormInput
            v-model.number="cpu_value.agentmobilephone"
            :disabled="disabled"
          ></FormInput>
        </el-form-item>
        <el-form-item prop="zw" label="职务">
          <FormInput v-model="cpu_value.zw" :disabled="disabled"></FormInput>
        </el-form-item>
        <el-form-item prop="lxrlx" label="联系人类型">
          <el-select
            v-model="cpu_value.lxrlx"
            placeholder="请选择联系人类型"
            clearable
            :style="{ width: '100%' }"
            :disabled="disabled"
          >
            <el-option
              v-for="(item, index) in field102Options"
              :key="index"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="QQ号">
          <FormInput v-model="cpu_value.qq" :disabled="disabled"></FormInput>
        </el-form-item>
        <el-form-item label="固定电话">
          <FormInput v-model="cpu_value.gddh" :disabled="disabled"></FormInput>
        </el-form-item>
        <el-form-item label="微信号">
          <FormInput v-model="cpu_value.wxh" :disabled="disabled"></FormInput>
        </el-form-item>
        <el-form-item prop="ssdq" label="所属地区">
          <el-select
            v-model="cpu_value.ssdq"
            placeholder="请选择所属地区"
            clearable
            :style="{ width: '100%' }"
            :disabled="disabled"
          >
            <el-option
              v-for="(item, index) in ssdqData"
              :key="index"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="邮箱">
          <FormInput
            v-model="cpu_value.agentemail"
            :disabled="disabled"
          ></FormInput>
        </el-form-item>
        <el-form-item label="传真">
          <FormInput v-model="cpu_value.chz" :disabled="disabled"></FormInput>
        </el-form-item>
        <el-form-item prop="agentaddress" label="联系地址">
          <FormInput
            v-model="cpu_value.agentaddress"
            placeholder=" "
            :disabled="disabled"
          ></FormInput>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { FormInput } from "@/components/form/index.js";
import { checkIdcard, checkPhone } from "@/utils/check.js";

export default {
  name: "Personal",
  components: {
    FormInput,
  },
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ssdqData: [], //所属地区
      field102Options: [],
      formRules: {
        agentname: [
          { required: true, message: "请输入经办人姓名", trigger: "blur" },
        ],
        agentcertificatenumber: [
          { required: true, message: "请输入身份证号码", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (!checkIdcard(value)) {
                callback(new Error("请输入正确的身份证号码"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        agentmobilephone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (!checkPhone(value)) {
                callback(new Error("请输入正确的手机号码"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        zw: [{ required: true, message: "请输入职务", trigger: "blur" }],
        lxrlx: [
          { required: true, message: "请选择联系人类型", trigger: "blur" },
        ],
        ssdq: [
          { required: true, message: "请输入经所属地区", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    cpu_value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  mounted() {
    this.getSsdq();
    this.getContactsType();
  },
  methods: {
    // 获取所属地区
    getSsdq() {
      this.getClassWrap("ssdq", "获取所属地区").then((res) => {
        this.ssdqData = res;
      });
    },
    async getClassWrap(el, title) {
      let endList = [];
      await this.getDictionaryInfo(el).then((res) => {
        if (res === 0) {
          console.log("字典暂无数据>>>");
        } else {
          // 处理数据
          // console.log(`${title || ''}处理前>>>`, res)
          const resList = JSON.parse(JSON.stringify(res.data));
          let target = [];
          for (const item of resList) {
            target.push({
              id: item.sort, // 0
              value: item.key, // "A"
              label: item.label, // "农、林..."
            });
          }
          // console.log(`获取${title || ''}处理后的数据>>>`, target)
          endList = target;
        }
      });
      return endList;
    },
    /**
     * 通用：根据字典类型获取字典信息
     */
    async getDictionaryInfo(el) {
      let response;
      await this.$httpApi
        .get("/dev-api/system/dict/getDictListByType", { dictType: el })
        .then((res) => {
          // console.log("根据字典类型获取字典信息>>>", res)
          // console.log("--------------------------------------------")
          if (res.code == 200 && res.data.length !== 0) {
            response = res;
          } else {
            response = 0;
          }
        })
        .catch((err) => {});
      return response;
    },
    /**
     * 联系人类型
     */
    getContactsType() {
      this.getClassWrap("sys_contacts_type", "获取联系人类型").then((res) => {
        this.field102Options = res;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.p-title {
  color: #000000;
  padding-bottom: 5px;
  padding-left: 10px;
  border-bottom: 4px solid #000000;
  margin-bottom: 10px;
}
</style>
